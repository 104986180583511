import { useEffect, useState } from "react";
import { useClassNames } from "@metaforcelabs/metaforce-core";
import { Disclosure } from '@headlessui/react'
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { CalendarIcon, XCircleIcon } from "@heroicons/react/outline";
import { ClickOutside } from "components/ClickOutside";

export default function DayRangePicker({
    id,
    label = null,
    dayRange = null,
    onChange,
    horizontal = false,
    className,
}) {
    const { classNames } = useClassNames();
    const [showDayPicker, setShowDayPicker] = useState(false);
    const [dayPickerRange, setDayPickerRange] = useState();
    const [inputDayRange, setInputDayRange] = useState();

    useEffect(() => {
        if (dayRange?.from && dayRange?.to) {
            setDayPickerRange(dayRange);
            setInputDayRange(`${dayRange.from?.toLocaleDateString() ?? ''} - ${dayRange.to?.toLocaleDateString() ?? ''}`);
        }
    }, [])

    const handleRangeSelect = (e) => {
        setDayPickerRange(e);
        setInputDayRange(`${e?.from?.toLocaleDateString() ?? ''} - ${e?.to?.toLocaleDateString() ?? ''}`);
        onChange(e);
    }

    const handleClear = () => {
        handleRangeSelect();
    }

    return (
        <div className={classNames(className, horizontal ? 'flex items-center mt-1' : '')}>
            {label && (
                <label
                    htmlFor={id}
                    className={classNames(
                        horizontal ? 'flex-1' : '',
                        'block text-sm text-gray-700'
                    )}
                >
                    {label}
                </label>
            )}
            <div className="">
                <div className="w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 flex items-center rounded-md border border-gray-300 bg-white">
                    <div className="relative flex-grow ">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <CalendarIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </div>
                        <input
                            type="text"
                            placeholder='Date Range'
                            value={inputDayRange}
                            className="hidden w-full pl-10 sm:block sm:text-sm rounded-md border-none focus:outline-none focus:ring-0"
                            readOnly
                            onClick={() => {
                                setShowDayPicker(true)
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setShowDayPicker(true)
                                }
                            }}
                        />
                    </div>
                    <button
                        type="button"
                        aria-label="clear date range"
                        className="shrink-0 select-none mr-2"
                        onClick={() => {
                            handleClear()
                        }}
                    >
                        <XCircleIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </button>
                </div>

                <div className="w-full relative">
                    <Disclosure>
                        {({ open }) => (
                            <>
                                {showDayPicker && (
                                    <ClickOutside onOutsideClick={() => {
                                        if (showDayPicker) {
                                            setShowDayPicker(false);
                                        }
                                    }}
                                    >
                                        <Disclosure.Panel
                                            static className="absolute top-0 left-0 z-50 shadow rounded-md bg-white mt-1"
                                        >
                                            <DayPicker
                                                className="w-full"
                                                mode="range"
                                                selected={dayPickerRange}
                                                onSelect={handleRangeSelect}
                                            />
                                        </Disclosure.Panel>
                                    </ClickOutside>
                                )}
                            </>
                        )}
                    </Disclosure>
                </div>
            </div>
        </div>

    )
}