import { ContentWrapper, PageHeader, useToastAction } from "@metaforcelabs/metaforce-core";
import NewWorkflowApp from "./newWorkflowApp";
import { useState, useEffect } from "react";
import { getOAuthAppConnectionsCount } from "../../../api/workflowOAuthAppConnection";
import { ChevronRightIcon, XCircleIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import { appConnectionType, oAuthAppConnections } from "../../../utils/constants";
import React from "react";
import EditKivraConnectionDialog from "./WorkflowOAuthAppConnections/Components/editKivraConnectionDialog";
import EditDigipostConnectionDialog from "./WorkflowOAuthAppConnections/Components/editDigipostConnectionDialog";
import EditSftpConnectionDialog from "./WorkflowOAuthAppConnections/Components/editSftpConnectionDialog";
import EditOpenAIConnectionDialog from "./WorkflowOAuthAppConnections/Components/editOpenAIConnectionDialog";

export default function WorkFlowApps() {
    const navigate = useNavigate();
    const [showNewDialog, setShowNewDialog] = useState(false);
    const [showEditKivraDialog, setShowEditKivraDialog] = useState(false);
    const [showEditDigipostDialog, setShowEditDigipostDialog] = useState(false);
    const [showEditSftpDialog, setShowEditSftpDialog] = useState(false);
    const [showEditOpenAIDialog, setShowEditOpenAIDialog] = useState(false);
    const [appConnections, setAppConnections] = useState([]);
    const loadAction = useToastAction();

    const loadData = async () => {
        loadAction.execute(async () => {
            const connectionCountResult = await getOAuthAppConnectionsCount();
            setAppConnections(connectionCountResult);
        }, "Failed to load connections");
    }

    useEffect(() => {
        loadData();
    }, [])

    const handleCloseNewDialog = async (type) => {
        setShowNewDialog(false);

        if (type === appConnectionType.kivra) {
            setTimeout(() => {
                setShowEditKivraDialog(true);
            }, 300);
        } else if (type === appConnectionType.digipost) {
            setTimeout(() => {
                setShowEditDigipostDialog(true);
            }, 300);
        } else if (type === appConnectionType.sftp) {
            setTimeout(() => {
                setShowEditSftpDialog(true);
            }, 300);
        } else if (type === appConnectionType.openAI) {
            setTimeout(() => {
                setShowEditOpenAIDialog(true);
            }, 300);
        } else {
            await loadData();
        }
    }

    const handleCloseEditKivraDialog = async () => {
        setShowNewDialog(false);
        setShowEditKivraDialog(false);
        await loadData();
    }

    const handleCloseEditDigipostDialog = async () => {
        setShowNewDialog(false);
        setShowEditDigipostDialog(false);
        await loadData();
    }

    const handleCloseEditSftpDialog = async () => {
        setShowNewDialog(false);
        setShowEditSftpDialog(false);
        await loadData();
    }

    const handleCloseEditOpenAIDialog = async () => {
        setShowNewDialog(false);
        setShowEditOpenAIDialog(false);
        await loadData();
    }

    return (
        <>
            <ContentWrapper>
                <div className="flex flex-row justify-between items-end">
                    <PageHeader
                        title="Apps"
                        optionalSideElement={
                            <button
                                type="button"
                                className="w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm"
                                onClick={() => setShowNewDialog(true)}
                            >
                                Add connection
                            </button>
                        }
                    />
                </div>

                <div className="mt-5 space-y-5">
                    {Object.keys(appConnectionType).map(k => {
                        const type = appConnectionType[k];
                        const appConnectionConfig = oAuthAppConnections.find(x => x.value === type);
                        const appConnection = appConnections?.find(x => x.appConnectionType === type);
                        if (!appConnection) {
                            return (<></>)
                        }

                        return (
                            <div className="px-6 py-4 rounded-md border border-gray-300 hover:shadow-lg cursor-pointer flex space-x-4 items-center"
                                onClick={() => {
                                    navigate(appConnectionConfig.href);
                                }}
                            >
                                <appConnectionConfig.icon className="h-8 w-8 rounded" />
                                <div className="flex-1">
                                    <h4 className="text-md font-semibold">{appConnectionConfig.name}</h4>
                                </div>
                                {appConnection.hasError && (
                                    <div title="Check app connection">
                                        <XCircleIcon className="h-8 w-8 text-red-500" />
                                    </div>
                                )}
                                <div className="flex flex-col items-center px-3">
                                    <div className="font-medium">{appConnection.count}</div>
                                    <div className="text-gray-500">{appConnection.count > 1 ? 'Connections' : 'Connection'}</div>
                                </div>
                                <div className="flex flex-col items-center px-3">
                                    <div className="font-medium">{appConnection.workflowCount}</div>
                                    <div className="text-gray-500">{appConnection.workflowCount > 1 ? 'Workflows' : 'Workflow'}</div>
                                </div>
                                <ChevronRightIcon className="h-5 w-5"></ChevronRightIcon>
                            </div>
                        )
                    })}

                </div>
            </ContentWrapper>
            <NewWorkflowApp open={showNewDialog} onClose={handleCloseNewDialog} />
            <EditKivraConnectionDialog open={showEditKivraDialog} onClose={handleCloseEditKivraDialog} />
            <EditDigipostConnectionDialog isOpen={showEditDigipostDialog} onClose={handleCloseEditDigipostDialog} />
            <EditSftpConnectionDialog isOpen={showEditSftpDialog} onClose={handleCloseEditSftpDialog} />
            <EditOpenAIConnectionDialog isOpen={showEditOpenAIDialog} onClose={handleCloseEditOpenAIDialog} />
        </>
    )
}