import { stringToLocaleDateTimeString } from "@metaforcelabs/metaforce-core";
import { workflowLogStatus, workflowLogStatusNames } from "../../../../utils/constants";
import { Check, Hand, Pause, Play } from "lucide-react";

export default function SideBarRunLog({ runLog, selectedRunLog, onChange }) {
    return (
        <div
            className={`border rounded-md px-5 py-4 w-full ${runLog.workflowValuesId === selectedRunLog?.workflowValuesId ? 'bg-indigo-50 border-indigo-200' : 'bg-white border-gray-300'} `}
            tabIndex={0}
            onClick={() => {
                onChange(runLog);
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter')
                    onChange(runLog);
            }}
        >
            <div className="flex space-x-3">
                <SidebarRunLogIcon status={runLog.status} />
                <span className="flex-1 text-sm font-medium">{workflowLogStatusNames[runLog.status]}</span>
                <span className="text-sm">{runLog.numberOfTasks} tasks</span>
            </div>
            <div className={`mt-2 text-sm  ${runLog.workflowValuesId === selectedRunLog?.workflowValuesId ? 'text-indigo-700' : 'text-gray-500'}`}>
                {stringToLocaleDateTimeString(runLog.createdDate)}
            </div>
        </div>
    )
}

const SidebarRunLogIcon = ({ status }) => {
    switch (status) {
        case workflowLogStatus.success:
            return (
                <div className='flex justify-center items-center rounded-md bg-green-600 text-white font-semibold p-0.5 h-5 w-5'>
                    <Check className="" />
                </div>
            )
        case workflowLogStatus.failed:
            return (
                <div className='flex justify-center items-center rounded-md bg-red-600 text-white font-semibold p-0.5 h-5 w-5'>
                    <Hand className="" />
                </div>
            )
        case workflowLogStatus.inProgress:
            return (
                <div className='flex justify-center items-center rounded-md bg-indigo-600 text-white font-semibold p-0.5 h-5 w-5'>
                    <Play className="" />
                </div>
            )
        case workflowLogStatus.waiting:
            return (
                <div className='flex justify-center items-center rounded-md bg-gray-400 text-white font-semibold p-0.5 h-5 w-5'>
                    <Pause className="" />
                </div>
            )
        default:
            return (
                <div className='flex justify-center items-center rounded-md bg-gray-400 text-white font-semibold h-5 w-5'>
                </div>
            )
    }
}