import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./styles.scss";
import { AdminLayoutContext, OidcRoutesContext } from "../../contexts";
import { config } from "../../utils/config";
import { Badge, MenuBar } from '@metaforcelabs/metaforce-core';
import { editStates, routePath } from "../../utils/constants";

export default function AdminLayout({ nav, subMenuNav, children }) {
  let { workflowId } = useParams();
  const oidcContext = useContext(OidcRoutesContext);
  const [currentWorkflow, setCurrentWorkflow] = useState()

  const NavigationMenuReactLinkElement = (
    name,
    route,
    currentCheckValue = nav === route,
    featureEnabledCheckResult = true,
    children
  ) => {
    return {
      linkElement: <Link to={route}>{name}</Link>,
      name: name,
      route: route,
      current: currentCheckValue,
      featureEnabled: featureEnabledCheckResult,
      children
    };
  };

  const navigationMenu = [
    NavigationMenuReactLinkElement(
      'Home',
      '/',
      nav === 'admin/dashboard' || nav === 'dashboard' || nav === 'admin/workflow',
      true
    ),
    NavigationMenuReactLinkElement(
      'Workflow',
      nav === 'admin/workflow/editor' ? `/admin/workflow/editor/${workflowId}` : `/admin/workflow/data/${workflowId}`,
      subMenuNav === 'admin/workflow',
      nav === 'admin/workflow/data' || nav === 'admin/workflow/editor' || nav === 'admin/workflow/versions',
      [
        NavigationMenuReactLinkElement('Editor', `/admin/workflow/editor/${workflowId}`, nav === 'admin/workflow/editor'),
        NavigationMenuReactLinkElement('Data', `/admin/workflow/data/${workflowId}`, nav === 'admin/workflow/data'),
        NavigationMenuReactLinkElement('Versions', `/admin/workflow/versions/${workflowId}`, nav === 'admin/workflow/versions'),
      ]
    ),
    NavigationMenuReactLinkElement(
      'List',
      '/admin/workflow-list',
      nav === 'admin/workflow_list',
      true,
    ),
    NavigationMenuReactLinkElement(
      'Apps',
      '/admin/workflow-apps',
      nav.startsWith('admin/workflow_apps'),
      true,
      nav.startsWith('admin/workflow_apps/') ?
        [
          NavigationMenuReactLinkElement('Microsoft Outlook', routePath.microsoftOutlook, nav.startsWith('admin/workflow_apps/microsoft-outlook')),
          NavigationMenuReactLinkElement('Kivra', routePath.kivra, nav.startsWith('admin/workflow_apps/kivra')),
          NavigationMenuReactLinkElement('Digipost', routePath.digipost, nav.startsWith('admin/workflow_apps/digipost')),
          NavigationMenuReactLinkElement('SFTP', routePath.sftp, nav.startsWith('admin/workflow_apps/sftp')),
          NavigationMenuReactLinkElement('OpenAI', routePath.openAI, nav.startsWith('admin/workflow_apps/openai')),
        ]
        :
        undefined,
    ),
    // NavigationMenuReactLinkElement(
    //   'Hooks',
    //   '/admin/hooks',
    //   nav.startsWith('/admin/hooks'),
    //   true,
    //   // nav.startsWith('/admin/hooks') ?
    //   //   [
    //   //     NavigationMenuReactLinkElement('Microsoft Outlook', routePath.microsoftOutlook, nav.startsWith('admin/workflow_apps/microsoft-outlook')),
    //   //   ]
    //   //   :
    //   //   undefined,
    // ),
  ];

  const sideBadge = () => {
    return <>
      {
        nav === 'admin/workflow/editor' && currentWorkflow?.editState === editStates.draft &&
        <Badge type="default" text="Draft" />
      }
      {
        nav === 'admin/workflow/editor' && currentWorkflow && currentWorkflow?.editState === editStates.published &&
        <Badge type="success" text="Published" />
      }

    </>
  }

  useEffect(() => {
    // console.log(currentWorkflow);
  }, [currentWorkflow])


  return (
    <>
      <AdminLayoutContext.Provider
        value={{
          currentWorkflow: currentWorkflow,
          setCurrentWorkflow
        }}
      >
        <div className={`fixed flex flex-col ${nav === 'admin/workflow/editor' ? "bg-#faf5e2 items-center" : "bg-gray-50"} h-screen w-screen overflow-auto`}>
          <MenuBar
            navigation={navigationMenu}
            homeButtonLink={config.centerpointUiBaseUrl}
            customerName={oidcContext?.customerInfo?.name}
            customerOrgNumber={oidcContext?.customerInfo?.orgNumber}
            userProfileImage={oidcContext?.userProfile?.userProfileImage}
            userName={oidcContext?.userProfile.fullName}
            helpLink={config.docsUrl}
            sideSubMenuElement={sideBadge()}
          />
          {children}
        </div>
      </AdminLayoutContext.Provider>
    </>
  );
}