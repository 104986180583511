import { useSortable } from "@dnd-kit/sortable";
import { useClassNames } from "@metaforcelabs/metaforce-core";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import React from "react";

export default function DndDragHandle({ workflowStep, isDragOverlayComponent, disabled = false }) {
    const { classNames } = useClassNames();
    const {
        attributes,
        listeners,
    } = useSortable({ id: workflowStep.id, disabled: disabled });

    return (
        <button
            type="button"
            id={`dndbutton-${workflowStep?.id}`}
            className={classNames("flex",
                 (isDragOverlayComponent ? ' cursor-grabbing ' : ' cursor-grab ')
            )}
            aria-label="Drag step"
            title="Drag step"
            {...listeners}
            {...attributes}
        >
            <DotsVerticalIcon className="-mr-2 h-6 w-6"/>
            <DotsVerticalIcon className="-ml-2 h-6 w-6"/>
        </button>
    )
}