import { useContext, useEffect, useState } from "react";
import CollabsableSection from "../collapsableSection";
import { useToastAction } from "@metaforcelabs/metaforce-core";
import { WorkflowContext } from "contexts";
import toast from "react-hot-toast";
import ConnectionOptionsMenu from "./connectionOptionsMenu";
import { getOAuthAppConnection, getOAuthAppConnections } from "../../../../../api/workflowOAuthAppConnection";
import { Link } from "react-router-dom";
import { appConnectionType, oAuthAppConnections, workflowAppType } from "../../../../../utils/constants";
import ViewpointAccountSelect from "./viewpointAccountSelect";

export default function AccountSelect({ setupStepIdx, activeStepSection, setActiveStep, workflowStep }) {
    const workFlowContext = useContext(WorkflowContext);
    const [connections, setConnections] = useState();
    const [selectedConnection, setSelectedConnection] = useState();
    const [selectedConnectionError, setSelectedConnectionError] = useState();

    const loadAction = useToastAction();

    const getAppConnectionType = (appEvent) => {
        switch (appEvent?.type) {
            case workflowAppType.microsoftOutlook:
                return appConnectionType.microsoftOutlook;
            case workflowAppType.kivra:
                return appConnectionType.kivra;
            case workflowAppType.digipost:
                return appConnectionType.digipost;
            case workflowAppType.sftp:
                return appConnectionType.sftp;
            case workflowAppType.openAI:
                return appConnectionType.openAI;
            default:
                return undefined;
        }
    }

    const loadData = async () => {
        loadAction.execute(async () => {
            if (workflowStep.appEvent?.type === workflowAppType.viewpoint)
                return;
            const connectionType = getAppConnectionType(workflowStep.appEvent);
            const connectionsResult = await getOAuthAppConnections(connectionType);
            setConnections(connectionsResult.connections);
        }, "Failed to load")
    }

    useEffect(() => {
        loadData();
    }, []);

    const notFoundHandler = () => {
        const connectionType = getAppConnectionType(workflowStep.appEvent);
        const oAuthAppConnection = oAuthAppConnections.find(x => x.value === connectionType);
        toast.error(`${oAuthAppConnection?.name} connection is not found.`);
        setSelectedConnectionError("Not Found");

        workflowStep.oAuthAppConnectionId = null;
        workFlowContext.updateWorkflowStep(workflowStep, workflowStep.id);
    }

    const loadSelectedConnection = async () => {
        loadAction.execute(async () => {
            if (workflowStep.appEvent?.type === workflowAppType.viewpoint)
                return;
            if (workflowStep.oAuthAppConnectionId) {
                const connection = await getOAuthAppConnection(workflowStep.oAuthAppConnectionId, notFoundHandler);
                setSelectedConnection(connection);
                if (connection?.isValid === false) {
                    toast.error(`Connection '${connection.name}' is invalid`);
                    setSelectedConnectionError("Invalid");
                }
            } else {
                setSelectedConnection(null);
            }
        }, "Failed to load")
    }

    useEffect(() => {
        loadSelectedConnection();
    }, [workflowStep.oAuthAppConnectionId])

    const handleConnectionChange = async (connection) => {
        if (connection.id !== workflowStep.oAuthAppConnectionId) {
            setSelectedConnection(connection);

            workFlowContext.handleStepConnectionChange([workflowStep], connection.id);

            workflowStep.oAuthAppConnectionId = connection.id;
            workFlowContext.updateWorkflowStep(workflowStep, workflowStep.id);
        }

        setSelectedConnectionError(null);
        setActiveStep(setupStepIdx + 1);
    }

    if (workflowStep.appEvent?.type === workflowAppType.viewpoint) {
        return (
            <ViewpointAccountSelect
                setupStepIdx={setupStepIdx}
                activeStepSection={activeStepSection}
                setActiveStep={setActiveStep}
                workflowStep={workflowStep}
                onConnectionChange={handleConnectionChange}
            />
        )
    }

    return (
        <>
            <CollabsableSection sectionTitle={"Account"} stepIdx={setupStepIdx} isActiveStep={activeStepSection === setupStepIdx} setActiveStep={setActiveStep}>
                <div className="mt-5">
                    <h4 className="mb-1">{workflowStep.appEvent?.type === workflowAppType.kivra ? 'Environment:' : 'Choose Account:'} </h4>
                    <ConnectionOptionsMenu
                        connections={connections}
                        selectedConnection={selectedConnection}
                        onSelect={option => handleConnectionChange(option)}
                        isLoading={loadAction.isExecuting}
                        error={selectedConnectionError}
                    />
                    <div className='mt-1 text-gray-500 text-sm'>
                        You have to go to <Link to="/admin/workflow-apps" className="text-indigo-700 underline">Apps</Link> to create connection
                    </div>
                </div>
            </CollabsableSection>
        </>
    )
}