import { ArchiveIcon, ChatAlt2Icon, ChipIcon, ClockIcon, FilterIcon, PencilAltIcon, RefreshIcon, ReplyIcon, ShareIcon } from '@heroicons/react/outline'
import { CenterPointIcon } from '@metaforcelabs/metaforce-core';
import React from 'react'
import { appEventIconName } from '../../../utils/constants';
import MicrosoftOutlookIcon from '../../../components/MicrosoftOutlookIcon';
import OpenAIIcon from '../../../components/OpenAIIcon';
import KivraIcon from '../../../components/KivraIcon';
import DigipostIcon from '../../../components/DigipostIcon';
import { FaFileCsv, FaFileShield,FaToolbox,FaWebhook } from 'components/FaIcon';

const appEventIconConfigs = {
    [appEventIconName.smartFormIcon]: {
        icon: ChatAlt2Icon,
        iconBackGround: "bg-green-50",
        iconForeGround: "text-green-700",
    },
    [appEventIconName.workFlowIcon]: {
        icon: ShareIcon,
        iconBackGround: "bg-yellow-50",
        iconForeGround: "text-yellow-700 transform rotate-90",
    },
    [appEventIconName.filterIcon]: {
        icon: FilterIcon,
        iconBackGround: "bg-brand-pink-lighter",
        iconForeGround: "text-brand-pink",
    },
    [appEventIconName.branchIcon]: {
        icon: ReplyIcon,
        iconBackGround: "bg-brand-pink-lighter",
        iconForeGround: "text-brand-pink transform -rotate-90",
    },
    [appEventIconName.loopIcon]: {
        icon: RefreshIcon,
        iconBackGround: "bg-brand-pink-lighter",
        iconForeGround: "text-brand-pink transform -rotate-90",
    },
    [appEventIconName.restApiIcon]: {
        icon: ChipIcon,
        iconBackGround: "bg-brand-pink-lighter",
        iconForeGround: "text-brand-pink transform -rotate-90",
    },
    [appEventIconName.distributionIcon]: {
        icon: ShareIcon,
        iconBackGround: "bg-brand-pink-lighter",
        iconForeGround: "text-brand-pink transform",
    },
    [appEventIconName.outlookIcon]: {
        icon: MicrosoftOutlookIcon,
        iconBackGround: "bg-blue-100",
        iconForeGround: "text-blue-500 transform",
    },
    [appEventIconName.webEditorIcon]: {
        icon: PencilAltIcon,
        iconBackGround: "bg-blue-50",
        iconForeGround: "text-indigo-700",
    },
    [appEventIconName.kivraIcon]: {
        icon: KivraIcon,
        iconBackGround: "bg-#8cc646",
        iconForeGround: "text-#8cc646",
    },
    [appEventIconName.digipostIcon]: {
        icon: DigipostIcon,
        iconBackGround: "bg-#d73027",
        iconForeGround: "text-#d73027",
    },
    [appEventIconName.chronIcon]: {
        icon: ClockIcon,
        iconBackGround: "bg-blue-600",
        iconForeGround: "text-white",
    },
    [appEventIconName.sftpIcon]: {
        icon: FaFileShield,
        iconBackGround: "bg-indigo-600",
        iconForeGround: "text-white",
    },
    [appEventIconName.jsonIcon]: {
        icon: () => <svg className='text-white h-full w-full' viewBox="0 0 81 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.802 29.017C6.31333 29.017 5.11467 28.611 4.206 27.799C3.29733 26.987 2.843 25.7883 2.843 24.203C2.843 23.565 2.89133 22.9753 2.988 22.434C3.08467 21.8927 3.18133 21.3513 3.278 20.81C3.394 20.2493 3.452 19.6307 3.452 18.954C3.452 18.2967 3.23933 17.7167 2.814 17.214C2.408 16.692 1.66367 16.3633 0.581001 16.228V13.241C1.66367 13.1057 2.408 12.7963 2.814 12.313C3.23933 11.8103 3.452 11.211 3.452 10.515C3.452 9.85767 3.394 9.25833 3.278 8.717C3.18133 8.15633 3.08467 7.60533 2.988 7.064C2.89133 6.52267 2.843 5.92333 2.843 5.266C2.843 3.7 3.29733 2.511 4.206 1.699C5.11467 0.886999 6.31333 0.480999 7.802 0.480999H9.629V3.555H8.498C7.106 3.555 6.41 4.24133 6.41 5.614C6.41 6.368 6.497 7.13167 6.671 7.905C6.86434 8.659 6.961 9.57733 6.961 10.66C6.961 11.6847 6.70967 12.5643 6.207 13.299C5.70433 14.0143 4.902 14.488 3.8 14.72V14.778C4.902 15.01 5.70433 15.4933 6.207 16.228C6.70967 16.9433 6.961 17.8133 6.961 18.838C6.961 19.9207 6.86434 20.8487 6.671 21.622C6.497 22.376 6.41 23.13 6.41 23.884C6.41 25.2567 7.106 25.943 8.498 25.943H9.629V29.017H7.802Z" fill="white" />
            <path d="M16.9175 24.264C15.4215 24.264 14.2188 23.846 13.3095 23.01C12.4148 22.1593 11.9675 20.9567 11.9675 19.402H14.7835C14.7982 20.1207 14.9668 20.6927 15.2895 21.118C15.6122 21.5433 16.1328 21.756 16.8515 21.756C17.5262 21.756 18.0175 21.558 18.3255 21.162C18.6335 20.7513 18.7875 20.216 18.7875 19.556V8.6H21.6035V19.556C21.6035 21.0667 21.1782 22.2327 20.3275 23.054C19.4915 23.8607 18.3548 24.264 16.9175 24.264Z" fill="white" />
            <path d="M29.8585 24.264C28.7292 24.264 27.7318 24.0733 26.8665 23.692C26.0012 23.296 25.3192 22.7387 24.8205 22.02C24.3218 21.2867 24.0652 20.3993 24.0505 19.358H27.0205C27.0498 20.0767 27.3065 20.6853 27.7905 21.184C28.2892 21.668 28.9712 21.91 29.8365 21.91C30.5845 21.91 31.1785 21.734 31.6185 21.382C32.0585 21.0153 32.2785 20.5313 32.2785 19.93C32.2785 19.2993 32.0805 18.808 31.6845 18.456C31.3032 18.104 30.7898 17.818 30.1445 17.598C29.4992 17.378 28.8098 17.1433 28.0765 16.894C26.8885 16.4833 25.9792 15.9553 25.3485 15.31C24.7325 14.6647 24.4245 13.8067 24.4245 12.736C24.4098 11.8267 24.6225 11.0493 25.0625 10.404C25.5172 9.744 26.1332 9.238 26.9105 8.886C27.6878 8.51933 28.5825 8.336 29.5945 8.336C30.6212 8.336 31.5232 8.51933 32.3005 8.886C33.0925 9.25267 33.7085 9.766 34.1485 10.426C34.6032 11.086 34.8452 11.8707 34.8745 12.78H31.8605C31.8458 12.2373 31.6332 11.7607 31.2225 11.35C30.8265 10.9247 30.2692 10.712 29.5505 10.712C28.9345 10.6973 28.4138 10.8513 27.9885 11.174C27.5778 11.482 27.3725 11.9367 27.3725 12.538C27.3725 13.0513 27.5338 13.462 27.8565 13.77C28.1792 14.0633 28.6192 14.3127 29.1765 14.518C29.7338 14.7233 30.3718 14.9433 31.0905 15.178C31.8532 15.442 32.5498 15.75 33.1805 16.102C33.8112 16.454 34.3172 16.9233 34.6985 17.51C35.0798 18.082 35.2705 18.8227 35.2705 19.732C35.2705 20.5387 35.0652 21.2867 34.6545 21.976C34.2438 22.6653 33.6352 23.2227 32.8285 23.648C32.0218 24.0587 31.0318 24.264 29.8585 24.264Z" fill="white" />
            <path d="M44.9434 24.264C43.4034 24.264 42.0541 23.9267 40.8954 23.252C39.7514 22.5773 38.8494 21.646 38.1894 20.458C37.5441 19.2553 37.2214 17.8693 37.2214 16.3C37.2214 14.7307 37.5441 13.352 38.1894 12.164C38.8494 10.9613 39.7514 10.0227 40.8954 9.348C42.0541 8.67333 43.4034 8.336 44.9434 8.336C46.4688 8.336 47.8108 8.67333 48.9694 9.348C50.1281 10.0227 51.0301 10.9613 51.6754 12.164C52.3208 13.352 52.6434 14.7307 52.6434 16.3C52.6434 17.8693 52.3208 19.2553 51.6754 20.458C51.0301 21.646 50.1281 22.5773 48.9694 23.252C47.8108 23.9267 46.4688 24.264 44.9434 24.264ZM44.9434 21.734C46.4101 21.734 47.5761 21.25 48.4414 20.282C49.3214 19.314 49.7614 17.9867 49.7614 16.3C49.7614 14.6133 49.3214 13.286 48.4414 12.318C47.5761 11.35 46.4101 10.866 44.9434 10.866C43.4768 10.866 42.3034 11.35 41.4234 12.318C40.5434 13.286 40.1034 14.6133 40.1034 16.3C40.1034 17.9867 40.5434 19.314 41.4234 20.282C42.3034 21.25 43.4768 21.734 44.9434 21.734Z" fill="white" />
            <path d="M55.1298 24V8.6H57.9458L65.1838 19.446V8.6H67.9998V24H65.1838L57.9458 13.176V24H55.1298Z" fill="currentColor" />
            <path d="M71.2873 29.017V25.943H72.3893C73.8006 25.943 74.5063 25.2567 74.5063 23.884C74.5063 23.13 74.4096 22.376 74.2163 21.622C74.0229 20.8487 73.9263 19.9207 73.9263 18.838C73.9263 17.8133 74.1776 16.9433 74.6803 16.228C75.1829 15.4933 75.9949 15.01 77.1163 14.778V14.72C75.9949 14.488 75.1829 14.0143 74.6803 13.299C74.1776 12.5643 73.9263 11.6847 73.9263 10.66C73.9263 9.57733 74.0229 8.659 74.2163 7.905C74.4096 7.13167 74.5063 6.368 74.5063 5.614C74.5063 4.24133 73.8006 3.555 72.3893 3.555H71.2873V0.480999H73.0853C74.5739 0.480999 75.7726 0.886999 76.6813 1.699C77.6093 2.511 78.0733 3.7 78.0733 5.266C78.0733 5.92333 78.0153 6.52267 77.8993 7.064C77.8026 7.60533 77.6963 8.15633 77.5803 8.717C77.4836 9.25833 77.4353 9.85767 77.4353 10.515C77.4353 11.211 77.6479 11.8103 78.0733 12.313C78.4986 12.7963 79.2526 13.1057 80.3353 13.241V16.228C79.2526 16.3633 78.4986 16.692 78.0733 17.214C77.6479 17.7167 77.4353 18.2967 77.4353 18.954C77.4353 19.6307 77.4836 20.2493 77.5803 20.81C77.6963 21.3513 77.8026 21.8927 77.8993 22.434C78.0153 22.9753 78.0733 23.565 78.0733 24.203C78.0733 25.7883 77.6093 26.987 76.6813 27.799C75.7726 28.611 74.5739 29.017 73.0853 29.017H71.2873Z" fill="white" />
        </svg>

        ,
        iconBackGround: "bg-green-800",
        iconForeGround: "text-white",
    },
    [appEventIconName.csvIcon]: {
        icon: FaFileCsv
        ,
        iconBackGround: "bg-green-600",
        iconForeGround: "text-white",
    },    
    [appEventIconName.webhookIcon]: {
        icon: FaWebhook,
        iconBackGround: "bg-brand-pink-lighter",
        iconForeGround: "text-brand-pink",
    },    
    [appEventIconName.viewpointIcon]: {
        icon: ArchiveIcon,
        iconBackGround: "bg-brand-pink-lighter",
        iconForeGround: "text-brand-pink",
    },
    [appEventIconName.archiveIcon]: {
        icon: ArchiveIcon,
        iconBackGround: "bg-green-50",
        iconForeGround: "text-green-700",
    },
    [appEventIconName.toolboxIcon]: {
        icon: FaToolbox,
        iconBackGround: "bg-brand-pink-lighter",
        iconForeGround: "text-brand-pink",
    }, 
    [appEventIconName.openAIIcon]: {
        icon: OpenAIIcon,
        iconBackGround: "bg-brand-pink-lighter",
        iconForeGround: "text-brand-pink",
    }, }

export default function WorkflowEventAppIcon({ iconKey, className, stateBadgeType = null, title = null }) {
    const appIcon = appEventIconConfigs[iconKey] || appEventIconConfigs[appEventIconName.smartFormIcon];
    return (
        <CenterPointIcon icon={appIcon.icon} iconBackGround={appIcon.iconBackGround} iconForeGround={appIcon.iconForeGround} className={className} title={title} stateBadgeType={stateBadgeType} />
    );
}
